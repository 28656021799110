import { createAsyncThunk } from '@reduxjs/toolkit';

// функция запроса в базу данных
export const fetchCatalog = createAsyncThunk('filter/fetchCatalog', async (_, { rejectWithValue }) => {
  try {
    const response = await fetch('https://test.sky-rise.site/api/index.php');

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();

    return data;
  } catch (error) {
    console.log(error.message);
    return rejectWithValue(error.message);
  }
});

export const findModelForId = createAsyncThunk('constructor/findModelForId', async (modelId) => {
  const response = await fetch(`https://test.sky-rise.site/api/model/${modelId}`);
  const data = await response.json();
  return data;
});
